import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { HTMLContent } from '../components/Content'
import DashboardPageTemplate from '../components/DashboardPageTemplate'
import Layout from '../components/Layout'

const DashboardPage = () => {
  return (
    <Layout includePreFooter>
      <DashboardPageTemplate      />
    </Layout>
  )
}

export default DashboardPage
