import React from 'react';
import GoogleMapReact from 'google-map-react';
//import MapMarkerCustom from './my_great_place.jsx';

const isClient = typeof window !== 'undefined';

const GoogleMap = (props) => {
  const {
      latitude,
      longitude,
    googleMapsApiKey,
    className
  } = props;

    
const lat = parseFloat(latitude);
const lng = parseFloat(longitude);
const myLatLng = { lat: parseFloat(latitude), lng: parseFloat(longitude) };


const handleApiLoaded = (map, maps) => {
        
    new google.maps.Marker({
      position: myLatLng,
      map,
      title: "Hello World!",
    });
    
  // use map and maps objects
};
    
    
  return (
          
          
    <section className={className}>
          <div style={{ height: '100vh', width: '100%' }}>

              { isClient && (
                <GoogleMapReact
                  bootstrapURLKeys={{ key: googleMapsApiKey }}
                  defaultCenter={[lat, lng]}
                  defaultZoom={14}
                 yesIWantToUseGoogleMapApiInternals
                 onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                  >
                             
                {/* <MapMarkerCustom lat={lat} lng={lng} text={'A'} /> */}

                </GoogleMapReact>
              )}
          </div>

    </section>
          
          
  );
}

export default GoogleMap
