import { getCookies, cookieName } from "./cookies";

export const fetchTagsByUser = async () => {
  try {
    const userData = getCookies(cookieName);
    const response = await fetch(
      "https://izpky7pwyjfv7fgl5am6sosmiu.appsync-api.us-east-2.amazonaws.com/graphql",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": "da2-rmrsvabj4rcytaouhly4d4mtwu",
        },
        body: JSON.stringify({
          query: `
            query MyQuery {
              getUserTag4l3liz36mvhohjid6satkgmksmDev(id: "${userData.sub}") {
                id
                userTag
              }
            }
          `,
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const result = await response.json();
    const userTagData = JSON.parse(
      result?.data?.getUserTag4l3liz36mvhohjid6satkgmksmDev?.userTag || "[]"
    );
    return userTagData;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
