// Dashboard Component

import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import PropTypes from "prop-types";
import Content from "../Content";
import GoogleMap from "../GoogleMap";

import handleClick from "../../handleClick.js";
import routeChange from "../../routeChange.js";

import useAuth from "../useAuth";
import { Amplify, Auth } from "aws-amplify";
import awsconfig from "../../aws-exports";
import ReactPaginate from "react-paginate";
import { getCookies, cookieName } from "../../cookies";
import { fetchTagsByUser } from "../../service";
Amplify.configure(awsconfig);

//import CarInterface from './car.tsx'

const DashboardPageTemplate = ({
  meta_title,
  meta_description,
  title,
  team,
  content,
  contentComponent,
}) => {
  const PageContent = contentComponent || Content;

  const { currentUser } = useAuth();
  const [userTagData, setUserTagData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const userTagData = await fetchTagsByUser();
      setUserTagData(userTagData);
      setPageCount(Math.ceil(userTagData.length / itemsPerPage));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = userTagData.slice(startIndex, endIndex);

  return (
    <div className="flex flex-col bg-indigo-100">
      <div className="container px-8 mx-auto">
        <Helmet>
          <title>{meta_title}</title>
          <meta name="description" content={meta_description} />
        </Helmet>

        <main className="flex flex-wrap items-center py-12 my-12 text-center text-gray-800">
          <div className="w-full whitespace-pre-line md:w-full">
            <h1 className="mb-6 text-5xl leading-tight">{title}</h1>
            <div class="w-full overflow-x-auto">
              <div>
                <h2 className="m-12 text-4xl leading-tight text-center text-gray-800">
                  Tags{" "}
                </h2>
              </div>
              <table className="table-auto w-full border-collapse">
                <thead>
                  <tr className="bg-indigo-600 text-white">
                    <th className="px-4 py-2 border">S No.</th>
                    <th className="px-4 py-2 border">Tag Url</th>
                  </tr>
                </thead>
                <tbody>
                  {currentData.map((item, index) => (
                    <tr key={index}>
                      <td className="px-4 py-2 border">
                        {index + startIndex + 1}
                      </td>
                      <td className="px-4 py-2 border">{item.tagUrl}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="pagination-container flex justify-center pt-3">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel="next >"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={10}
                  pageCount={pageCount}
                  previousLabel="< previous"
                  renderOnZeroPageCount={null}
                  className="pagination"
                  pageClassName="page-item"
                  activeClassName="active-page-item"
                  containerClassName="page-container"
                />
              </div>
            </div>

            {currentUser !== null ? (
              <div>
                <div>
                  {" "}
                  Blocktag dashboard work in progress. Please email
                  hello@blocktag.com if you have questions or click button below
                  to download tags for printing!
                </div>

                <button
                  onClick={() => {
                    handleClick("DownloadTagNavBar");
                    routeChange("https://generate.blocktag.com");
                    window.lintrk("DownloadTagNavBar", {
                      conversion_id: 12784580,
                    }); // LinkedIn Ads
                  }}
                  class="flex-grow inline-block px-8 py-2 my-4 mr-5 text-sm text-white bg-teal-500 border border-teal-500 rounded sm:my-0 hover:bg-teal-700 hover:border-teal-700"
                >
                  Test Print Tags
                </button>
              </div>
            ) : null}

            <PageContent className="mb-6 markdown" content={content} />
          </div>
        </main>

        {/* Google Maps */}
        <h2 className="m-12 text-4xl leading-tight text-center text-gray-800">
          Authentication{" "}
        </h2>

        <GoogleMap
          latitude={"-25.363"}
          longitude={"131.044"}
          googleMapsApiKey={"AIzaSyD0hV0NTl3WpJe4WJy72fkQZ6g_va6L5oE"}
        />
      </div>
    </div>
  );
};

DashboardPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

export default DashboardPageTemplate;
